import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Field, Form } from '~/components/form';
import AutocompleteAddressField from '~/components/inputs/autocomplete-address-input/autocomplete-address-field';
import Button from '~/components/button';
import { useAddressInput } from '~/hooks/use-address-input';
import { required, usAddress, valid } from '~/utils/validation';
import {
  newLawnRoute,
  helpCenterRoute,
  zipcodeLawnSizeRoute,
  yardIssuesPageRoute,
} from '~/routes';
import { EventType, analyticsBeacon } from '~/utils/analytics';
import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';

import styles from '~/components/static/home/smart-lawn-plan-section/smart-lawn-plan-section.module.scss';

const HeroInputForm = ({
  variant,
  analyticsId,
  className,
  type = AutocompleteTypes.FULL_ADDRESS,
  initialValues = {},
  isYardFunnelEnabled,
  buttonVariant,
}) => {
  const navigate = useNavigate();
  const { calculateNextRoute, isCurrentSubscriber, isLoadingSubscriptions } =
    useAddressInput();

  const handleSubmit = async (formValues) => {
    analyticsBeacon.emit(EventType.ADDRESS_CTA_CLICKED, {
      url: window.location.pathname,
      inputId: analyticsId,
      type,
    });

    if (type === AutocompleteTypes.ZIP_CODE) {
      if (isYardFunnelEnabled) {
        return setTimeout(() => {
          navigate(yardIssuesPageRoute(formValues.address));
        }, 0);
      } else {
        return setTimeout(() => {
          navigate(zipcodeLawnSizeRoute(formValues.address));
        }, 0);
      }
    }

    // Timeout to slightly delay navigation while we send our analytics event
    setTimeout(() => {
      navigate(newLawnRoute(formValues.address));
    }, 0);
  };

  const validateAddressForm = (formValues) => {
    return {
      address: valid(
        required(
          <span
            className={cx({ [styles.errorMessageLight]: variant === 'light' })}
          >
            Make sure to select{' '}
            {type === AutocompleteTypes.ZIP_CODE ? 'a zip code' : 'an address'}{' '}
            from the dropdown. If your{' '}
            {type === AutocompleteTypes.ZIP_CODE ? 'zip code' : 'address'}{' '}
            doesn't appear{' '}
            <a
              style={{ color: 'inherit', fontWeight: '500' }}
              href={
                helpCenterRoute() + '?a=Contact-us---id--QtSDAj-sRAulw9HUjz4osg'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              visit our help center
            </a>{' '}
            for support.
          </span>
        ),
        usAddress()
      )(formValues.address),
    };
  };

  if (isCurrentSubscriber) {
    return (
      <Button
        className={cx(styles.heroAddressButton, styles.buttonOnly)}
        variant={buttonVariant ?? variant === 'light' ? 'yellow' : null}
        to={calculateNextRoute()}
        fullWidthMobile
        style={{
          visibility: isLoadingSubscriptions ? 'hidden' : 'visible',
        }}
        data-heap-id="current-subscriber-see-plan-cta"
      >
        See my plan
      </Button>
    );
  }

  return (
    <Form
      className={cx(styles.heroAddressForm, className)}
      onSubmit={handleSubmit}
      validate={validateAddressForm}
      style={{
        visibility: isLoadingSubscriptions ? 'hidden' : 'visible',
      }}
      initialValues={{ address: initialValues?.address }}
    >
      {() => (
        <>
          <Field
            className={styles.heroAddressInput}
            component={AutocompleteAddressField}
            label={
              type === AutocompleteTypes.ZIP_CODE
                ? 'Where do you live?'
                : 'Home address'
            }
            labelClassname={cx({
              [styles.labelLight]: variant === 'light',
              [styles.bold]: type === AutocompleteTypes.ZIP_CODE,
            })}
            name="address"
            placeholder={
              type === AutocompleteTypes.ZIP_CODE
                ? 'Enter your zip code'
                : 'Enter your home address'
            }
            type={type}
            initialValue={initialValues?.addressText}
          />

          <Button
            className={styles.heroAddressButton}
            fullWidthMobile
            variant={buttonVariant ?? variant === 'light' ? 'yellow' : null}
            type="submit"
            data-heap-id="smart-lawn-address-input-cta"
          >
            Get lawn analysis
          </Button>
        </>
      )}
    </Form>
  );
};

export default HeroInputForm;
